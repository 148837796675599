<template>
	<div class="animated fadeIn">
		<div class="container">
			<div class="form-group">
				<label
					for="event_code_name"
					class="label font-weight-bold">{{ translate('event') }}
					<span class="text-danger">*</span></label>
				<p>{{ translate(form.event_code_name) }}</p>
			</div>

			<b-card
				v-if="$can('event_tickets', 'transfer')"
				no-body
				class="h-100">
				<template
					v-slot:header>
					<h4 class="mb-0">
						{{ translate('transfer_ticket') }}
					</h4>
				</template>
				<b-card-body class="pb-3">
					<is-loading
						:loading="loading"
						:has-data="hasData"
						:loading-label="translate('loading')"
						:no-data-label="translate('ticket_not_found')" />
					<b-form
						v-if="showForm"
						@submit.prevent="transferTicket()"
						@keydown="clearError($event.target.name);"
						@change="clearError($event.target.name);">
						<div class="form-group">
							<search
								id="username"
								v-model="form.username"
								:label-text="translate('username')"
								:endpoint="endpoint"
								required
								:empty-result-message="translate('data_not_found')"
								:default-params="{role:'distributor'}"
								:custom-error="hasError('username') ? errors['username'][0] : false"
								:init-value="form.username"
								q="label"
								return-key="username"
								@input="clearError('username')" />
						</div>

						<div
							v-if="!data.is_parent"
							class="form-group mt-2">
							<label for="attendant_type">{{ translate('attendee_type') }}</label>
							<select
								id="attendant_type"
								v-model="form.attendant_type"
								:class="hasError('attendant_type') ? 'is-invalid' : ''"
								name="attendant_type"
								class="form-control">
								<option
									v-for="option in data.attendees_options"
									:key="option"
									:value="option">
									{{ translate(option) }}
								</option>
							</select>
						</div>

						<button
							:disabled="Object.keys(errors).length > 0 || editLoading"
							class="btn btn-primary float-right">
							<i
								v-if="editLoading"
								class="fas fa-spinner fa-spin mr-2" />
							{{ translate('save') }}
						</button>
					</b-form>
				</b-card-body>
			</b-card>

			<b-card
				v-if="$can('event_tickets', 'update_notes')"
				no-body
				class="h-100 mt-3">
				<template
					v-slot:header>
					<h4 class="mb-0">
						{{ translate('update_state_province') }}
					</h4>
				</template>
				<b-card-body class="pb-3">
					<is-loading
						:loading="loading"
						:has-data="hasData"
						:loading-label="translate('loading')"
						:no-data-label="translate('ticket_not_found')" />
					<b-form
						v-if="showForm"
						@submit.prevent="updateEventTicketRegion()"
						@keydown="clearError($event.target.name);"
						@change="clearError($event.target.name);">
						<div
							v-if="data.country_code"
							class="form-group mt-2">
							<label for="region_id"><strong>{{ translate('region') }}</strong></label>
							<select
								id="region_id"
								v-model="form.region_id"
								:class="hasError('region_id') ? 'is-invalid' : ''"
								name="region_id"
								class="form-control">
								<option :value="null">
									{{ translate('select_state') }}
								</option>
								<option
									v-for="state in regions"
									:key="state.id"
									:value="state.id">
									{{ state.attributes.name }}
								</option>
							</select>
						</div>

						<button
							:disabled="Object.keys(errors).length > 0 || editLoading"
							class="btn btn-primary float-right">
							<i
								v-if="editLoading"
								class="fas fa-spinner fa-spin mr-2" />
							{{ translate('save') }}
						</button>
					</b-form>
				</b-card-body>
			</b-card>

			<b-card
				v-if="$can('event_tickets', 'update_ticket_data')"
				no-body
				class="h-100 mt-3">
				<template
					v-slot:header>
					<h4 class="mb-0">
						{{ translate('update_ticket_names') }}
					</h4>
				</template>
				<b-card-body class="pb-3">
					<is-loading
						:loading="loading"
						:has-data="hasData"
						:loading-label="translate('loading')"
						:no-data-label="translate('ticket_not_found')" />
					<b-form
						v-if="showForm"
						class="row"
						@submit.prevent=""
						@keydown="clearError($event.target.name);"
						@change="clearError($event.target.name);">
						<input-text
							id="first_name"
							:label="translate('first_name')"
							:setter-value="form.first_name"
							:errors="errors['first_name']"
							:required="true"
							type="text"
							class="col-md-6 col-xs-12"
							@dataChanged="form.first_name = $event" />

						<input-text
							id="last_name"
							:label="translate('last_name')"
							:setter-value="form.last_name"
							:errors="errors['last_name']"
							:required="true"
							type="text"
							class="col-md-6 col-xs-12"
							@dataChanged="form.last_name = $event" />

						<!-- <div class="form-group col-md-2 col-sm-12">
							<label class="label mr-5 mt-2"> {{ translate('guest_number') }} </label>
							<number-input-spinner
								v-model="form.guest_number"
								:mouse-down-speed="500"
								:min="0"
								:max="99"
								:button-class="'vnis__button custom-height bg-primary-alt'"
								:input-class="'vnis__input custom-height custom-input-width'"
								:integer-only="true"
								class="col" />
						</div> -->

						<div class="col-12">
							<button
								:disabled="Object.keys(errors).length > 0 || editLoading"
								class="btn btn-primary float-right"
								@click="updateEventTicketNames()">
								<i
									v-if="editLoading"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('save') }}
							</button>
						</div>
					</b-form>
				</b-card-body>
			</b-card>

			<b-card
				v-if="$can('event_tickets', 'update_ticket_data') && ticketAttributes.can_edit_hotel_information"
				no-body
				class="h-100 mt-3">
				<template v-slot:header>
					<h4 class="mb-0">
						{{ translate('update_hotel_information') }}
					</h4>
				</template>
				<b-card-body>
					<is-loading
						:loading="loading"
						:has-data="hasData"
						:loading-label="translate('loading')"
						:no-data-label="translate('ticket_not_found')" />
					<div v-if="Object.keys(ticketAttributes).length > 0 && !loading">
						<div>
							<p class="my-2">
								<strong>{{ translate('user') }}:</strong> {{ ticketAttributes.username }} ({{ ticketAttributes.user_id }})
							</p>
							<p class="my-2">
								<strong>{{ translate(ticketAttributes.related_tickets.length > 0 ? 'ticket_names' : 'ticket_name') }}:</strong> {{ ticketNames }}
							</p>
							<p class="my-2">
								<strong>{{ translate('ticket_number') }}:</strong> {{ ticketNumbers }}
							</p>
							<p class="my-2">
								<strong>{{ translate('event') }}:</strong> {{ translate(ticketAttributes.event.event_code_name) }}
							</p>
							<hr>
							<b-row>
								<b-col>
									<b-form-group class="form-group">
										<label
											for="check_in_date"
											class="label font-weight-bold">{{ translate('check_in_date') }}
											<span class="text-danger">*</span></label>
										<select
											v-if="$can('event_tickets', 'update_ticket_data')"
											id="check_in_date"
											v-model="checkInDate"
											name="check_in_date"
											class="form-control">
											<option
												v-for="(date, index) in computedCheckInDates"
												:key="index"
												selected
												:value="date">
												{{ $moment(date).format(ordinalDateFormat) }}
											</option>
										</select>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group class="form-group">
										<label
											for="check_out_date"
											class="label font-weight-bold">{{ translate('check_out_date') }}
											<span class="text-danger">*</span></label>
										<select
											v-if="$can('event_tickets', 'update_ticket_data')"
											id="check_out_date"
											v-model="checkOutDate"
											name="check_out_date"
											class="form-control">
											<option
												v-for="(date, index) in computedCheckOutDates"
												:key="index"
												selected
												:value="date">
												{{ $moment(date).format(ordinalDateFormat) }}
											</option>
										</select>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
						<div v-if="ticketAttributes.available_room_types">
							<b-row class="mt-3">
								<b-col cols="5">
									<b-form-group class="form-group">
										<label
											for="room_type"
											class="label font-weight-bold">{{ translate('room_type') }}
											<span class="text-danger">*</span></label>
										<b-form-radio-group
											v-if="$can('event_tickets', 'update_ticket_data')"
											v-model="roomType"
											stacked>
											<b-form-radio
												v-for="(room_type, index) in ticketAttributes.available_room_types"
												:key="index"
												:value="room_type">
												{{ translate(room_type) }}
											</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
								<b-col cols="7">
									<div>
										<div class="d-flex align-items-center justify-content-around">
											<div>
												<img
													class="card-img"
													:style="{'max-height': '85px', 'max-width': '85px'}"
													:src="require(`@/assets/images/themes/${themeName}/tickets/${roomType === 'single_room' ? 'kingSize_100x100_orange' : 'kingSize_100x100_gray'}.png`)">
											</div>
											<div>
												<img
													class="card-img"
													:style="{'max-height': '85px', 'max-width': '85px'}"
													:src="require(`@/assets/images/themes/${themeName}/tickets/${roomType === 'double_room' ? 'queenSize_100x100_orange' : 'queenSize_100x100_gray'}.png`)">
											</div>
										</div>
									</div>
								</b-col>
							</b-row>
						</div>
						<div class="no-gutters justify-content-end text-right">
							<button
								:disabled="Object.keys(errors).length > 0 || editLoading"
								type="button"
								style="max-width: 200px"
								class="btn btn-primary"
								@click="saveReservationDates()">
								<i
									v-if="editLoading"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('save') }}
							</button>
						</div>
					</div>
				</b-card-body>
			</b-card>
		</div>
	</div>
</template>
<script>

// import NumberInputSpinner from 'vue-number-input-spinner';
import Ticket from '@/util/EventTickets';
import { EventTickets, Events, Grids } from '@/translations';
import Country from '@/util/Country';
import { SEARCH_USERS } from '@/config/endpoint';
import InputText from '@/components/InputText';
import HotelInformation from '@/views/Orders/mixins/HotelInformation';
import { YMD_FORMAT } from '@/settings/Dates';

export default {
	name: 'EditEventTicket',
	messages: [EventTickets, Events, Grids],
	components: {
		InputText,
		// NumberInputSpinner,
	},
	mixins: [HotelInformation],
	data() {
		return {
			ticket: new Ticket(),
			editTicket: new Ticket(),
			country: new Country(),
			form: {
				event_code_name: '',
				username: '',
				first_name: '',
				last_name: '',
				guest_number: 0,
				region_id: null,
				attendant_type: null,
			},
			alert: new this.$Alert(),
			errors: {},
			endpoint: SEARCH_USERS.endpoint,
		};
	},
	computed: {
		response() {
			try {
				const { data } = this.ticket.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		metaHotelInformation() {
			try {
				return this.response.meta.hotel_information;
			} catch (error) {
				return {};
			}
		},
		data: {
			get() {
				try {
					return this.response.data.attributes;
				} catch (error) {
					return {};
				}
			},
			set(payload) {
				this.response.data.attributes = { ...this.response.data.attributes, ...payload };
			},
		},
		loading() {
			try {
				return this.ticket.data.loading;
			} catch (error) {
				return false;
			}
		},
		editLoading() {
			try {
				return this.editTicket.data.loading;
			} catch (error) {
				return false;
			}
		},
		routeId() {
			return this.$route.params.ticketId;
		},
		regions() {
			try {
				const { data } = this.country.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		showForm() {
			return !this.loading && this.hasData;
		},
		hasData() {
			return Object.values(this.data).length > 0;
		},
		ticketAttributes() {
			return this.data;
		},
	},
	watch: {
		routeId() {
			this.ticket.getTicket(this.routeId);
		},
		data() {
			this.checkInDate = this.data.check_in_date ? this.$moment(this.data.check_in_date, YMD_FORMAT).toDate() : this.eventDate().toDate();
			this.checkOutDate = this.data.check_out_date ? this.$moment(this.data.check_out_date, YMD_FORMAT).toDate() : this.eventDate().add(this.data.reservation_nights, 'days').toDate();
			this.roomType = this.data.room_type;
			this.fetchFormData();
		},
		checkInDate() {
			this.checkOutDate = this.$moment(this.checkInDate).add(this.data.reservation_nights, 'days').toDate();
		},
	},
	created() {
		this.ticket.getTicket(this.routeId).then(() => {
			this.setFocus('username');

			if (this.data.country_code) {
				this.country.getStates(this.data.country_code);
			}
		});
	},
	methods: {
		transferTicket() {
			const payload = {
				username: this.form.username,
				attendant_type: this.form.attendant_type,
			};
			this.editTicket.transferTicket(this.routeId, payload).then(() => {
				this.alert.toast('success', this.translate('ticket_updated'));
				this.$router.push({ name: 'EventTicketsAll' });
			}).catch(() => {
				this.errors = { ...this.editTicket.data.errors.errors };
				this.data = payload;
			});
		},
		updateEventTicketRegion() {
			const payload = {
				region_id: this.form.region_id,
			};
			this.editTicket.updateTicketRegion(this.routeId, payload).then(() => {
				this.alert.toast('success', this.translate('ticket_updated'));
				this.$router.push({ name: 'EventTicketsAll' });
			}).catch(() => {
				this.errors = { ...this.editTicket.data.errors.errors };
				this.data = payload;
			});
		},
		updateEventTicketNames() {
			const payload = {
				first_name: this.form.first_name,
				last_name: this.form.last_name,
				guest_number: this.form.guest_number,
			};
			this.editTicket.updateTicketNames(this.routeId, payload).then(() => {
				this.alert.toast('success', this.translate('ticket_updated'));
				this.$router.push({ name: 'EventTicketsAll' });
			}).catch(() => {
				this.errors = { ...this.editTicket.data.errors.errors };
				this.data = payload;
			});
		},
		saveReservationDates() {
			const payload = {
				check_in_date: this.$moment(this.checkInDate).format(YMD_FORMAT),
				check_out_date: this.$moment(this.checkOutDate).format(YMD_FORMAT),
				room_type: this.roomType,
			};
			this.editTicket.hotelReservation(this.routeId, payload).then(() => {
				this.alert.toast('success', this.translate('ticket_updated'));
				this.$router.push({ name: 'EventTicketsAll' });
			}).catch(() => {
				const errorName = 'default_error_message';
				this.alert.toast('error', this.translate(errorName));
			});
		},
		hasError(field) {
			return typeof this.errors[field] !== 'undefined';
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		fetchFormData() {
			Object.keys(this.form).forEach((keyValue) => {
				this.form[keyValue] = this.data[keyValue];
			});
			this.form.event_code_name = this.data.event.event_code_name;
		},
	},
};
</script>
<style scoped>
.vnis {
	padding: 0 !important;
}
</style>
